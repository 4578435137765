.dashboard {
    @media screen and (max-width: 960px) {
        .p-datatable {
            &.p-datatable-customers {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr {
                    border-bottom: 1px solid #dee2e6;

                    >td {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: .4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -.4rem 1rem -.4rem -.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: .5rem;
                        }
                    }
                }
            }
        }
    }
}

.floatlabel-demo {
    .p-field {
        margin-top: 2rem;
        margin-bottom: 0;
    }
}

.p-multiselect {
    width: 100%;
}

.input-demo {
    .p-multiselect {
        min-width: 15rem;
    }

    .multiselect-custom {
        .country-item {
            display: flex;
            align-items: center;

            span.flag {
                width: 18px;
                height: 12px;
                margin-right: .5rem;
                margin-left: .5rem;
            }
        }

        .country-item-value {
            border-radius: 3px;
            display: inline-flex;
            margin-right: .5rem;
            background-color: #2196F3;
            color: #ffffff;
        }
    }
}

.list-demo {
    .product-name {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .product-description {
        margin: 0 0 1rem 0;
    }

    .product-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
    }

    .product-category {
        font-weight: 600;
        vertical-align: middle;
    }

    .product-list-item {
        display: flex;
        align-items: center;
        padding: 1rem;
        width: 100%;

        img {
            width: 150px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin-right: 2rem;
        }

        .product-list-detail {
            flex: 1 1 0;
        }

        .p-rating {
            margin: 0 0 .5rem 0;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: .5rem;
            align-self: flex-end;
        }

        .product-list-action {
            display: flex;
            flex-direction: column;
        }

        .p-button {
            margin-bottom: .5rem;
        }

    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-instock {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-outofstock {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-lowstock {
            background: #FEEDAF;
            color: #8A5340;
        }
    }

    .product-grid-item {
        margin: .5em;
        border: 1px solid #dee2e6;

        .product-grid-item-top,
        .product-grid-item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        img {
            width: 75%;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin: 2rem 0;
        }

        .product-grid-item-content {
            text-align: center;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    @media screen and (max-width: 576px) {
        .product-list-item {
            flex-direction: column;
            align-items: center;

            img {
                width: 75%;
                margin: 2rem 0;
            }

            .product-list-detail {
                text-align: center;
            }

            .product-price {
                align-self: center;
            }

            .product-list-action {
                display: flex;
                flex-direction: column;
            }

            .product-list-action {
                margin-top: 2rem;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }
}

.menu-demo {
    .stepsdemo-content {
        padding: 1em 0;

        p {
            font-weight: 400;
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            margin: 0;
        }

        i {
            vertical-align: middle;
            font-size: 1.5em;
            margin: 0;
        }
    }

    .contextmenu-image {
        width: 100%;
    }
}

.p-calendar {
    width: 100%;
}

.p-inputtext {
    width: 100%;
}

.messages-demo {
    .p-field>label {
        width: 125px;
    }

    .p-inputtext {
        margin-right: .5rem;
    }
}

.p-dropdown {
    width: 100%;
}

.misc-demo {
    .badges {

        .p-badge,
        .p-tag {
            margin-right: .5rem;
        }

        .p-tag {
            line-height: 1;
        }
    }

    .p-button {
        margin-right: .5rem;

    }

    .p-overlay-badge {
        margin-right: 2rem;
    }
}

.overlay-demo {
    p {
        line-height: 1.5;
        margin: 0;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.panel-demo {
    p {
        line-height: 1.5;
        margin: 0;
    }

    .p-card {
        .p-card-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;
            padding: 1rem 1rem 0 1rem;
        }

        .p-card-body {
            padding-top: 0;
        }
    }
}

.table-demo {
    .p-progressbar {
        height: .5rem;
        background-color: #D8DADC;

        .p-progressbar-value {
            background-color: #607D8B;
        }
    }

    .p-datatable .p-column-filter {
        display: none;
    }

    .table-header {
        display: flex;
        justify-content: space-between;
    }

    .p-datatable.p-datatable-customers {
        .p-datatable-header {
            padding: 1rem;
            text-align: left;
            font-size: 1.5rem;
        }

        .p-paginator {
            padding: 1rem;
        }

        .p-datatable-thead>tr>th {
            text-align: left;
        }

        .p-datatable-tbody>tr>td {
            cursor: auto;
        }

        .p-dropdown-label:not(.p-placeholder) {
            text-transform: uppercase;
        }
    }

    /* Responsive */
    .p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
        display: none;
    }

    .customer-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-qualified {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-unqualified {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-negotiation {
            background: #FEEDAF;
            color: #8A5340;
        }

        &.status-new {
            background: #B3E5FC;
            color: #23547B;
        }

        &.status-renewal {
            background: #ECCFFF;
            color: #694382;
        }

        &.status-proposal {
            background: #FFD8B2;
            color: #805B36;
        }
    }

    .p-progressbar-value.ui-widget-header {
        background: #607d8b;
    }

    @media (max-width: 640px) {
        .p-progressbar {
            margin-top: .5rem;
        }
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }

    .orders-subtable {
        padding: 1rem;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-instock {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-outofstock {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-lowstock {
            background: #FEEDAF;
            color: #8A5340;
        }
    }

    .order-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.order-delivered {
            background: #C8E6C9;
            color: #256029;
        }

        &.order-cancelled {
            background: #FFCDD2;
            color: #C63737;
        }

        &.order-pending {
            background: #FEEDAF;
            color: #8A5340;
        }

        &.order-returned {
            background: #ECCFFF;
            color: #694382;
        }
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.p-datatable-customers {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr {
                    >td {
                        text-align: left;
                        display: block;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: .4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -.4rem 1rem -.4rem -.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: .5rem;
                        }
                    }
                }
            }
        }
    }
}

.text-demo {
    .demo-container {
        border: 2px solid #dee2e6;
    }
}

.spacing-demo {
    .demo-container {
        border: 1px solid #dee2e6;
    }
}

.icons-demo {
    .icons-list {
        text-align: center;
        color: #6c757d;

        .p-md-2 {
            padding: 1em;
        }
    }

    .icons-list i {
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }
}

.p-grid {
    width: 100%;
}

.flexgrid-demo {
    .box {
        background-color: #ffffff;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    }

    .box-stretched {
        height: 100%;
    }

    .vertical-container {
        margin: 0;
        height: 200px;
        background: #dee2e6;
        border-radius: 4px;
    }

    .nested-grid .p-col-4 {
        padding-bottom: 1rem;
    }

    .dynamic-box-enter-active,
    .dynamic-box-leave-active {
        transition: all .5s;
    }

    .dynamic-box-enter-from,
    .dynamic-box-leave-to {
        opacity: 0;
    }

    .dynamic-box-enter-from,
    .dynamic-box-leave-to {
        transform: translateX(30px);
    }

    p {
        margin: 0;
    }
}

.fc-event-time {
    visibility: hidden;
    width: 10px;
}

.huynt-input-required {
    border: 1px solid #f90020;
}

.huynt-text-required {
    color: #f90020;
}

.huynt-text-blue {
    color: blue;
}

.layout-main {
    background-color: white;
}

.no-padding {
    padding: 0px !important;

}

.fc-resourceTimelineDay-button.fc-button.fc-button-primary {
    background: #ffffff !important;
    border: 1px solid #ced4da !important;
    color: #495057 !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}

.fc-resourceTimelineWeek-button.fc-button.fc-button-primary {
    background: #ffffff !important;
    border: 1px solid #ced4da !important;
    color: #495057 !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}

.fc-resourceTimelineYear-button.fc-button.fc-button-primary {
    background: #ffffff !important;
    border: 1px solid #ced4da !important;
    color: #495057 !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}
.p-autocomplete {
    width: 100%;
}
.fc-resourceTimelineYear-button.fc-button.fc-button-primary.fc-button-active {
    color: #ffffff !important;
    background: #2196F3 !important;
    border: 1px solid #2196F3 !important;
    font-size: 1rem !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}

.p-inputtextarea.p-inputtext.p-component {
    max-width: -webkit-fill-available  !important;
}

.fc-license-message {
    content-visibility: hidden;
}

.huynt-warning {
    background-color: #f3e4e4 !important;
}

.huynt-limit-button {
    padding: 2px !important;
}

.fc-resourceTimelineWeek-button.fc-button.fc-button-primary.fc-button-active {
    color: #ffffff !important;
    background: #2196F3 !important;
    border: 1px solid #2196F3 !important;
    font-size: 1rem !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}

.fc-resourceTimelineMonth-button.fc-button.fc-button-primary {
    background: #ffffff !important;
    border: 1px solid #ced4da !important;
    color: #495057 !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}

.fc-resourceTimelineMonth-button.fc-button.fc-button-primary.fc-button-active {
    color: #ffffff !important;
    background: #2196F3 !important;
    border: 1px solid #2196F3 !important;
    font-size: 1rem !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}

.fc-resourceTimelineDay-button.fc-button.fc-button-primary.fc-button-active {
    color: #ffffff !important;
    background: #2196F3 !important;
    border: 1px solid #2196F3 !important;
    font-size: 1rem !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}

.align-items-center {
    align-items: center !important;
}

.huynt-minus {
    color: red
}

.huynt-button-invisible {
    border: none !important;
    color: inherit !important;
    background-color: inherit !important;
}

.huynt-unlimit {
    width: max-content;
}

.huynt-table {
    background-color: var(--surface-e);
    text-align: center;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #C4C4C4;
    margin-right: 10px;
    width: 300px;
    float: left;


}

.huynt-table-header {
    background-color: #F6F6F6;
    height: 40px;
    margin: 0px;
    border-bottom: 1px solid #C4C4C4;
    font-size: 16px;
    align-content: space-around;
}

.huynt-table-body {
    // background-color: #F6F6F6;
    height: 300px;
    margin: 0px;
    font-size: 16px;
    align-content: space-around;
    padding-bottom: 10px;
    border-bottom: 1px solid #C4C4C4;
    text-align: left;
    display: inline-flex;
}

.huynt-table-body:last-child {
    border-bottom: none
}

.huynt-table-body-header {
    color: black;
    font-weight: bold;
    margin-top: 21px;
    margin-bottom: 21px
}

.huynt-table-body-status {
    color: white;
    background-color: #22C55E;
    margin-left: 10px;
    text-align: center;
    align-self: center;
    border-radius: 2px;
    height: 25px
}

.huynt-table-text {
    text-align: left;
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.huynt-table-text-brown {
    color: #707070;
    font-weight: 400;
}


.flexbox-demo {
    margin-bottom: 1.5rem;

    .p-d-flex>div {
        background-color: var(--surface-e);
        text-align: center;
        padding: 1rem;
        border-radius: 4px;
        -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);

    }

    .box {
        background-color: #ffffff;
        text-align: center;
        padding: 1rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    }

    .p-d-flex>div {
        width: 9rem;
    }
}

.elevation-demo {
    .box {
        min-height: 100px;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        margin: 2rem;
        border-radius: 4px;
    }

    .shadow-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.crud-demo {
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-instock {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-outofstock {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-lowstock {
            background: #FEEDAF;
            color: #8A5340;
        }
    }

    /* Responsive */
    .datatable-responsive .p-datatable-tbody>tr>td .p-column-title {
        display: none;
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.datatable-responsive {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                    word-break: break-all;
                }

                .p-datatable-tbody>tr {
                    >td {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: .4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -.4rem 1rem -.4rem -.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: .5rem;
                        }

                        .actions {
                            display: flex;
                            flex-grow: 1;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

.docs {
    h1 {
        margin-top: 28px;
        margin-bottom: 14px;
    }

    p {
        line-height: 1.5;
    }
}

pre[class*="language-"] {

    &:after,
    &:before {
        box-shadow: none;
    }

    padding: 0 !important;
    background: transparent;
    overflow: visible;

    >code {
        box-shadow: none !important;
        border-left: 10px solid #0388E5;
    }
}

.input-invisible {
    visibility: hidden;
}

.hidden-outline {
    &:focus {
        outline: none
    }
}

.flash-border-color {
    border: solid;
    border-color: #f59999;
    -webkit-transition: border-color 3s ease !important;
    transition: border-color 3s ease !important;
}

.unset-border-color {
    border: solid;
    border-color: #ffffff;
    -webkit-transition: border-color 3s ease !important;
    transition: border-color 3s ease !important;
}

.unset-border {
    border: solid;
    border-color: #ffffff;
}

.p-datatable .p-datatable-tbody>tr>td {
    word-break: break-all;
}

.p-inputnumber {
    width: 100%;
}

.flex-demo-click {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border: 1px solid #2196f3;
}

.flex-demo-hover {
    &:hover {
        border: 1px solid #2196f3;
    }
}

.p-datatable-auto-layout>.p-datatable-wrapper {
    overflow-x: hidden
}

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12 {
    padding: 0;
}

.content-visibility {
    content-visibility: hidden;
}